import clsx from "clsx";
import EntryThumbnail from "components/EntryThumbnail";
import dayjs from "dayjs";
import React from "react";

import { getEntryCategoryArray } from "@lib/getEntryCategoryArray";
import { getSummaryFromHtml } from "@lib/getSummaryFromHtml";

interface IProps {
	entries: any[];
	indentMobile?: boolean;
	showDate?: boolean;
	showAuthor?: boolean;
	thumbnailAspectRatio?: string;
	size?: string;
	showCategories?: boolean;
	linkDirectlyToAsset?: boolean;
	showSummary?: boolean;
	transparent?: boolean;
	columns?: number;
}

const EntryGrid = ({
	entries,
	indentMobile = false,
	showDate = false,
	showAuthor = true,
	showCategories = true,
	thumbnailAspectRatio = "landscape",
	columns = 4,
	linkDirectlyToAsset = false,
	showSummary = true,
	size = "md",
	transparent = false,
}: IProps): JSX.Element => {
	return (
		<div
			className={clsx(
				"EntryGrid",
				"max-w-screen-xl",
				"mx-auto md:px-5 lg:px-5"
			)}
		>
			<div
				className={clsx("EntryGrid__entries", "grid grid-cols-1 gap-7.5", {
					"md:grid-cols-2 lg:grid-cols-4": columns === 4,
					"md:grid-cols-2 lg:grid-cols-3": columns === 3,
					"md:grid-cols-1 lg:grid-cols-2": columns === 2,
				})}
			>
				{entries &&
					entries.map((entry, index) => {
						const image = !!entry.image ? entry.image[0] : null;
						const imageBlur = !!entry.imageBlur ? entry.imageBlur[0] : null;

						// Gather all possible categories from the entry
						const categories = getEntryCategoryArray(entry);

						// Check if there is a summary, otherwise we'll have to get it from teh body
						if (!entry.summary) {
							entry.summary = getSummaryFromHtml(entry.legacyBlogContent);
						}

						const parsedDate = dayjs(entry.postDate);

						const targetImage =
							thumbnailAspectRatio === "square" && !!image ? image : image;

						return (
							<div
								key={index}
								className={clsx("EntryGrid__entryCard", {
									"bg-white-01": !transparent,
								})}
							>
								<EntryThumbnail
									key={entry.id}
									image={targetImage}
									imageBlur={imageBlur}
									heading={entry.title}
                  summary={entry.summary}
                  categories={categories}
                  showCategories={false}
									target={
                    linkDirectlyToAsset
                      /* If the entry has an asset, use the asset as the target */
                      /* Otherwise use the entry itself */
                      ? entry?.target?.url && entry.target.type === "asset"
                        ? entry.target
                        : entry.downloadableFiles?.length > 0
                          ? { element: entry.downloadableFiles[0], type: "asset" }
                          : { element: entry, type: "entry" }
											: { element: entry, type: "entry" }
									}
									measureImageHeight={index === 0}
									externalUrl={entry.externalUrl}
									captionPadding={!transparent}
									size="sm"
									date={
										showDate ? parsedDate.format("MMMM D, YYYY") : undefined
									}
									source={entry.publication}
									authors={showAuthor && entry.postAuthors}
									isAsset={
										linkDirectlyToAsset && (entry?.target?.url && entry.target.type === "asset") || entry.downloadableFiles?.length > 0
											? true
											: false
									}
									showSummary={showSummary}
								/>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default EntryGrid;

import truncate from "lodash/truncate";

export const getSummaryFromHtml = (html) => {
  const response = html;
  const regex = /<p>(.*?)<\/p>/;
  const corresp = regex.exec(response);
  const firstParagraphWithoutHtml = corresp ? corresp[1] : ""; // text1
  return truncate(firstParagraphWithoutHtml.replace(/(<([^>]+)>)/gi, ""), {
    length: 210,
  });
};

import clsx from "clsx";
import Image from "next/image";

const Author = ({ name, image }) => {
	return (
		<div className={clsx("Author", "flex items-center")}>
			<div
				className={clsx(
					"Author__image",
					"w-7.5 h-7.5",
					"bg-white-03",
					"rounded-full",
					"overflow-hidden",
					"flex justify-center items-center"
				)}
			>
				{!!image?.url && (
					<Image
						src={image.url}
						height={30}
						width={30}
						alt={image.title}
						className={clsx("Author__img")}
					/>
				)}
				{!image && (
					<div className={clsx("Author__placeholder")}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 24 24"
							fill="none"
							stroke="#000000"
							strokeWidth="1"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
							<circle cx="12" cy="7" r="4"></circle>
						</svg>
					</div>
				)}
			</div>
			<div className={clsx("Author__name", "ml-4")}>{name}</div>
		</div>
	);
};

export default Author;

export const getEntryCategoryArray = (entry) => {
  let categories = [];

  if (!!entry.topics) categories = [...categories, ...entry.topics];
  if (!!entry.resourceTypes)
    categories = [...categories, ...entry.resourceTypes];
  if (!!entry.industries) categories = [...categories, ...entry.industries];
  if (!!entry.entryTypes) categories = [...categories, ...entry.entryTypes];
  if (!!entry.newsTypes) categories = [...categories, ...entry.newsTypes];
  if (!!entry.blogCategory)
    // TODO: change to plural
    categories = [...categories, ...entry.blogCategory];

  return categories;
};

import clsx from "clsx";
import Image from 'next/image'
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import useMeasure from "react-use-measure";

import EntryThumbnailHeader from "@components/EntryThumbnailHeader";
import { ArrowRight } from "@components/Icons";
import Target from "@components/Target";
import fallbackImage from "@images/abstract/floor_dark_green_1.FINAL.jpg";
import getImageBlurOptions from "@lib/getImageBlurOptions";

const EntryThumbnail = ({
	image,
	imageBlur,
	heading,
	summary,
	target,
	categories = [],
	size = "md",
	authors = [],
	externalUrl = "",
	indentMobile = false,
	date = null,
	onImageHeight = (height) => null,
	aspectRatio = "landscape",
	source = null,
	measureImageHeight = true,
	showCategories = true,
	isAsset = false,
	showSummary = true,
	captionPadding = false,
}) => {
	const { ref, inView } = useInView();

	const [imageRef, imageBounds] = useMeasure();

	useEffect(() => {
		if (measureImageHeight) onImageHeight(imageBounds.height);
  }, [measureImageHeight, onImageHeight, imageBounds.height]);
  
	const targetTarget = externalUrl ? { type: "url", url: externalUrl } : target;

  let resourceType = null;
	{
		categories &&
			categories.map((category) => {
				if (category.slug == "webinars" || category.slug == "videos") {
					resourceType = "video";
				}
				if (
					category.slug == "threat-intelligence-reports" ||
					category.slug == "threat-reports" ||
					category.slug == "white-papers"
				) {
					resourceType = "downloadable";
				}
			});
	}
	if (isAsset) {
		resourceType = "downloadable";
	}

	return (
		<div
			className={clsx(
				"EntryThumbnail relative",
				"transition-opacity duration-500 ease-out",
				"h-full",
				{
					"opacity-0": !inView,
					"opacity-100": inView,
				}
			)}
			ref={ref}
		>
			<div className={clsx("EntryThumbnail__imageContainer")} ref={imageRef}>
				<Target
					target={targetTarget}
					className={clsx("EntryThumbnail__imageWrapper", "block", {
						"mb-7.5": size !== "sm",
						"mb-3": size === "sm",
					})}
				>
					{!!image && image.entryThumb && (
						<Image
							src={process.env.NODE_ENV === "production" ? image.entryThumb[0].url : image.url}
							height={image.entryThumb[0].height / 2}
							width={image.entryThumb[0].width / 2}
							alt={image.title}
							className={clsx("EntryThumbnail__image")}
							draggable={false}
							blurDataURL={image.entryThumb[1].url}
						/>
					)}
					{!image && (
						<div
							className={clsx("EntryThumbnail__fallbackImage", "aspect-w-16", {
								"aspect-h-10": aspectRatio === "landscape",
								"aspect-h-16": aspectRatio === "square",
							})}
						>
							<Image
								src={fallbackImage}
								alt={heading}
								layout="fill"
								objectFit="cover"
								draggable={false}
								className={clsx("EntryThumbnail__image--fallback")}
							/>
						</div>
					)}
				</Target>
			</div>

			<div
				className={clsx("EntryThumbnail__caption", "relative", {
					"px-5": captionPadding,
					"pb-20": size !== "sm",
					"pb-15": size === "sm",
				})}
			>
				<EntryThumbnailHeader
					heading={heading}
					categories={showCategories && categories}
					authors={authors}
					size={size}
					target={targetTarget}
					indentMobile={indentMobile}
					date={date}
					source={source}
				/>
				{showSummary && (
					<div
						className={clsx(
							"EntryThumbnail__summary line-clamp-4",
							"max-w-lg",
							{ "text-sm": size === "sm" },
							{
								"px-7.5 md:pl-0": indentMobile,
							},
							"lg:pr-7.5"
						)}
					>
						{summary}
					</div>
				)}
			</div>
			<Target
				target={targetTarget}
				className={clsx(
					"EntryThumbnail__target",
					"flex items-center",
					"hover-underline",
					"group",
					"absolute",
					{ "text-sm": size === "sm" },

					"bottom-0 left-0 right-0 w-full",
					{
						"px-7.5 md:pl-0": indentMobile,
						"px-5 pb-5": captionPadding,
						"md:pl-0 lg:pr-7.5 py-7.5": !captionPadding,
					}
				)}
			>
				<div
					className={clsx(
						"EntryThumbnail__targetIcon",
						"mr-4",
						"transition-transform group-hover:translate-x-1"
					)}
				>
					<ArrowRight />
				</div>
				<span className={clsx("inline-block pb-[2px]")}>
					{resourceType && resourceType == "video" && "Watch Now"}
					{resourceType && resourceType == "downloadable" && "Download Now"}
					{!resourceType && "Read More"}
				</span>
			</Target>
		</div>
	);
};

export default EntryThumbnail;
